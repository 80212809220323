/**
 * 客服操作js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";

const request = axios.request;
/**
 * 获取项目客服信息
 * @returns {Promise<unknown>}
 */
export const getrefprojectcsstaff = (data) => {
  return request.doPost('/refprojectcsstaff/getprojectCsStaff', data);
}

/**
 * 添加或者修改项目客服信息
 * @returns {Promise<unknown>}
 */
export const addorupdaterefprojectcsstaff = (data) => {
  return request.doPost('/refprojectcsstaff/addOrUpdateprojectCsStaff', data);
}

/**
 * 删除项目客服信息
 * @returns {Promise<unknown>}
 */
export const deleterefprojectcsstaff = (data) => {
  return request.doPost('/refprojectcsstaff/deleteprojectCsStaff', data);
}

/**
 * 获取数据下拉
 * @returns {Promise<unknown>}
 */
export const getProjCsStaffDropDown = (data) => {
  return request.doPost('/refprojectcsstaff/getProjCsStaffDropDown', data);
}
