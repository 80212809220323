<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.realname" placeholder="客服姓名" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-input v-model="listQuery.projname" placeholder="项目名称" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">
        添加
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" sortable="custom" align="center" width="80">
        <template slot-scope="{row}">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客服编号" sortable="custom" align="center" width="120">
        <template slot-scope="{row}">
          <span>{{ row.csid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目编号" sortable="custom" align="center" width="120">
        <template slot-scope="{row}">
          <span>{{ row.projid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="真实姓名" width="200px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.realname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="200px">
        <template slot-scope="{row}">
          <span>{{ row.projname}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center"  fixed="right"  class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row,$index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog :title="textMap[dialogaccount_status]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 500px; margin-left:50px;">
        <el-form-item label="选择项目" prop="projid">
          <el-select v-model="temp.projid" placeholder="请选择项目" clearable style="width: 300px">
            <template v-for="pro in projectList">
              <el-option :label="pro.projname" :value="pro.projid"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="选择客服" prop="csid">
          <el-select v-model="temp.csid" placeholder="请选择客服" clearable style="width: 300px">
            <template v-for="csstaff in csstaffList">
              <el-option :label="csstaff.realname" :value="csstaff.csid"></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogaccount_status==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {getrefprojectcsstaff,addorupdaterefprojectcsstaff,deleterefprojectcsstaff,getProjCsStaffDropDown} from "@/apis/csstaff-project.js";
  import { parseTime } from '@/utils'
  export default {
    name: 'refcsstaffproject',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      account_statusFilter(account_status) {
        const account_statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return account_statusMap[account_status]
      },
      parseTime:parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 20,
          realname:'',
          projname:'',
          auid:null
        },
        projectList:[],
        csstaffList:[],
        account_statusOptions: ['published', 'draft', 'deleted'],
        showReviewer: false,
        temp: {
          id: null,
          csid: '',
          projid: '',
        },
        dialogFormVisible: false,
        dialogaccount_status: '',
        textMap: {
          update: '修改',
          create: '添加'
        },
        dialogPvVisible: false,
        pvData: [],
        rules: {
          projid: [{ required: true, message: '请选择项目', trigger: 'change' }],
          csid: [{  required: true, message: '请选择客服', trigger: 'change' }]
        },
        downloadLoading: false
      }
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.adminuid=this.user.userId
        getrefprojectcsstaff(this.listQuery).then(res => {
        const {count = 0} = res;
        this.list=res.retdata.dataList;
        this.total=count;
        this.projectList=res.retdata.projectList;
        this.csstaffList=res.retdata.csstaffList;
        this.listLoading = false;
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      resetTemp() {
        this.temp = {
          id: undefined,
          csid: '',
          projid: '',
        }
      },
      handleCreate() {
        this.resetTemp()
        this.dialogaccount_status = 'create'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      createData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.auid=this.user.userId
            addorupdaterefprojectcsstaff(this.temp).then(res =>{
              console.log(this.temp)
              this.$message.success(res.retDesc);
              this.dialogFormVisible=false;
              this.getList();
            });
          }
        })
      },
      handleUpdate(row) {
        console.log(row)
        this.temp = Object.assign({}, row) // copy obj
        if (this.temp.gender=='0'){
          this.temp.gender=0
        }else {
          this.temp.gender=1;
        }
        this.dialogaccount_status = 'update'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      updateData() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.temp.auid=this.user.userId
            addorupdaterefprojectcsstaff(this.temp).then(res =>{
              this.$message.success(res.retDesc);
              this.dialogFormVisible=false;
              this.getList();
            });
          }
        })
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      },
      handleDelete(row, index) {
        this.temp = Object.assign({}, row)
        var id=this.temp.id;
        deleterefprojectcsstaff({id}).then(res =>{
          this.$message.success(res.retDesc);
          this.list.splice(index, 1)
        });
      },
    }
  }
</script>

<style scoped>
.filter-item {
  margin-left:5px;
}

</style>
